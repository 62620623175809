@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}

textarea {
  resize: none !important;
}

html {
  scroll-behavior: smooth !important;
  scroll-padding-top: 120px !important;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 50;
  transition: transform 0.3s ease-in-out;
}

.text-shadow-yellow {
  text-shadow: 2px 2px 0px #fdbe0f;
  color: #006fba;
}

/* .title-3 {
  font-size: 40px;
  line-height: 55px;
} */

@media screen and (min-width: 1536px) {
  .title-1 {
    /* font-size: 60px; */
    /* font-weight: normal; */
    text-shadow: 1px 1px 0px #fdbe0f;
    color: #006fba;
    /* line-height: 60px; */
  }

  .title-3 {
    /* font-size: 60px; */
    /* font-weight: normal; */
    color: #fff;
    /* line-height: 60px; */
  }
}

.title-2 {
  /* font-size: 40px; */
  /* font-weight: normal; */
  color: #006fba;
  /* line-height: 55px; */
}

/* @media screen and (max-width: 768px) {
  .title-1 {
    font-size: 25px;
    line-height: 40px;
  }

  .title-3 {
    font-size: 25px;
    line-height: 40px;
  }

  .title-hero {
    font-size: 25px;
    line-height: 40px;
  }

  .title-2 {
    font-size: 25px;
    line-height: 40px;
  }
} */

.testimonialsSlider>.swiper-wrapper>.swiper-slide:not(.swiper-slide-active) {
  filter: grayscale(100%);
}

.testimonialsSlider>.swiper-wrapper>.swiper-slide-active>.testimonial-image {
  height: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

@media screen and (max-width: 768px) {
  .testimonialsSlider>.swiper-wrapper>.swiper-slide-active>.testimonial-image {
    height: 100px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }
}

.clientsGrid {
  grid-template-columns: 30% 70%;
}

:root {
  --color-primary: #006fba;
  --color-secondary: #f0f7ee;
  --duration: 1s;
  --nav-duration: calc(var(--duration) / 4);
  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  --space: 1rem;
  --font-size: 1.125rem;
  --line-height: 1.5;
}

* {
  box-sizing: border-box;
}

.main-navigation-toggle {
  position: fixed;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  z-index: 150;
}

.main-navigation-toggle+label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  cursor: pointer;
  z-index: 999;
}

.icon--menu-toggle {
  --size: calc(1rem + 4vmin);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  stroke-width: 6;
}

.extra-posts-styles h2 {
  text-transform: uppercase !important;
  color: #006fba !important;
  font-weight: bold;
  margin-bottom: 5px !important;
  font-size: 20px !important;
}

.extra-posts-styles a:not(.bg-blue-light) {
  font-weight: 600;
  text-decoration: underline;
}
.extra-posts-styles h3 > font{
  color: #006fba !important;
}
.extra-posts-styles h2 > strong > font{
  color: #006fba !important;
}
.extra-posts-styles h3 {
  font-weight: bold;
  color: #006fba !important;
  margin-bottom: 5px !important;
  font-size: 18px !important;
}

.extra-posts-styles h3 {
  padding-left: 20px;
}

td {
  border: 1px solid #000 !important;
  padding: 10px !important;
}
table {
  min-width: 500px !important;
}
.extra-posts-styles h3 ~ p {
  padding-left: 20px;
}

.extra-posts-styles h2,
.extra-posts-styles h1 ~ p {
  padding-left: 0;
}
@media screen and (max-width: 768px) {
  .extra-posts-styles h2 {
    font-size: 15px !important; 
  }

  .extra-posts-styles h3 {
    font-size: 13px !important; 
  }
  .extra-posts-styles li {
    margin-left: 6px !important;
  }
}
.extra-posts-styles li {
  margin-left: 10px !important;
}

.extra-posts-styles h3 {
  padding-left: 10px;
}

.extra-posts-styles h3 ~ p {
  padding-left: 10px;
}

.icon-group {
  transform: translateX(0);
  transition: transform var(--nav-duration) var(--ease);
}



.icon--close {
  transform: translateX(-100%);
}

.main-navigation {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: transform var(--nav-duration);
  z-index: 100;
}

.main-navigation:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  transform-origin: 0 50%;
  z-index: -1;
}

.main-navigation ul {
  font-size: 30px;

  width: 100%;
}

.main-navigation li {
  --border-size: 1vmin;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.main-navigation li:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--border-size);
  background-color: var(--color-secondary);
  transform-origin: 0 50%;
  transform: translateX(-100%) skew(15deg);
}

.main-navigation a {
  display: inline-block;
  width: 100%;

  margin: 0 auto;
  color: var(--color-secondary);
  line-height: 1;
  text-decoration: none;
  user-select: none;
  padding: var(--space) calc(var(--space) * 2) calc(var(--space) + var(--border-size) / 2);
  transform: translateY(100%);
}

.page-wrapper {
  transform: translateX(0);
  transition: transform calc(var(--nav-duration) * 2) var(--ease);
}

.page-wrapper>*+* {
  margin-top: calc(var(--space) * var(--line-height));
}

.main-navigation-toggle:checked~label .icon--menu-toggle .icon-group {
  transform: translateX(100%);
}

.main-navigation-toggle:checked~.page-wrapper {
  transform: translateX(10%);
}

.showMobileMenu {
  transition-duration: 0s;
  transform: translateX(0);
}

.showMobileMenu:after {
  animation: nav-bg var(--nav-duration) var(--ease) forwards;
}

.showMobileMenu li:after {
  animation: nav-line var(--duration) var(--ease) forwards;
}

.showMobileMenu a {
  animation: link-appear calc(var(--duration) * 1.5) var(--ease) forwards;
}

.showMobileMenu li:nth-child(1):after,
.showMobileMenu li:nth-child(1) a {
  animation-delay: calc((var(--duration) / 2) * 1 * 0.125);
}

.showMobileMenu li:nth-child(2):after,
.showMobileMenu li:nth-child(2) a {
  animation-delay: calc((var(--duration) / 2) * 2 * 0.125);
}

.showMobileMenu li:nth-child(3):after,
.showMobileMenu li:nth-child(3) a {
  animation-delay: calc((var(--duration) / 2) * 3 * 0.125);
}

.showMobileMenu li:nth-child(4):after,
.showMobileMenu li:nth-child(4) a {
  animation-delay: calc((var(--duration) / 2) * 4 * 0.125);
}

.showMobileMenu li:nth-child(4):after,
.showMobileMenu li:nth-child(5) a {
  animation-delay: calc((var(--duration) / 2) * 5 * 0.125);
}

.showMobileMenu li:nth-child(4):after,
.showMobileMenu li:nth-child(6) a {
  animation-delay: calc((var(--duration) / 2) * 6 * 0.125);
}

.showMobileMenu li:nth-child(4):after,
.showMobileMenu li:nth-child(7) a {
  animation-delay: calc((var(--duration) / 2) * 7 * 0.125);
}

.showMobileMenu li:nth-child(4):after,
.showMobileMenu li:nth-child(8) a {
  animation-delay: calc((var(--duration) / 2) * 8 * 0.125);
}

.showMobileMenu li:nth-child(4):after,
.showMobileMenu li:nth-child(9) a {
  animation-delay: calc((var(--duration) / 2) * 9 * 0.125);
}

@keyframes nav-bg {
  from {
    transform: translateX(-100%) skewX(-15deg);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes nav-line {
  0% {
    transform: scaleX(0);
    transform-origin: 0 50%;
  }

  35% {
    transform: scaleX(1.001);
    transform-origin: 0 50%;
  }

  65% {
    transform: scaleX(1.001);
    transform-origin: 100% 50%;
  }

  100% {
    transform: scaleX(0);
    transform-origin: 100% 50%;
  }
}

@keyframes link-appear {

  0%,
  25% {
    transform: translateY(100%);
  }

  50%,
  100% {
    transform: translateY(0);
  }
}

/* --------------------------------- navbar --------------------------------- */

.flexF {
  display: flex !important;
}

.testimonial {
  opacity: 0;
  transition: opacity 1s ease, transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.testimonial:not(.testimonial) {
  opacity: 1;
}

/* loader */
#loader {
  /* display: none; Oculta el loader por defecto */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
}

#loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* loader */

.text-shadow-1 {
  text-shadow: 1px 1px 1px #FFFFFF;
}

ol {
  list-style-type: decimal !important;
}

ul {
  list-style-type: disc !important;
}


.swiper-button-prev,
.swiper-button-next {
  --un-bg-opacity: 1;
  color: #fdbe0f !important;
}

@media screen and (max-width: 1200px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}

.swiper-pagination-bullet {
  background: #006fba !important;
}

table {
  width: 100%;
  margin-top: 20px;
}

li {
  list-style-position: inside;
}



.not-showing {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s ease-in;

}

.showing {
  visibility: visible;
  transition: visibility 0.3s, opacity 0.3s ease-in;
  opacity: 1;
}